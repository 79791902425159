import type { FC } from "react";
import { memo, useMemo } from "react";

import { pluck, uniq } from "ramda";

import { Box, Center } from "@chakra-ui/react";
import { RouteApi } from "@tanstack/react-router";

import { Beamtime } from "@app/domain/api/agent/beamtime.ts";
import { MeasurementTask } from "@app/domain/api/measurementTask.ts";
import { Organization } from "@app/domain/api/organization.ts";
import { Request } from "@app/domain/api/request.ts";
import { Sample } from "@app/domain/api/sample.ts";

import { PageLayout } from "@mt-components/Layout/PageLayout.tsx";
import { LoadingSwirl } from "@mt-components/states/Loading/LoadingSwirl.tsx";

import { BeamtimeDetails } from "./BeamtimeDetails.tsx";

const routeApi = new RouteApi({ id: "/agent/beamtimes/$beamtimeId" });

export const BeamtimeDetailsContainer: FC = () => {
    const params = routeApi.useParams();

    const organizationsQuery = Organization.useAdminGetAll();
    const beamtimeQuery = Beamtime.useAdminGet({
        beamtimeId: params.beamtimeId,
    });
    const requestQuery = Request.useAdminGetAll({
        query: {
            filter: {
                beamtimeId: params.beamtimeId,
            },
        },
    });
    const samplesQuery = Sample.useAdminGetAll();

    const measuremengGroupIds = pluck("id", requestQuery.data || []);

    const tasksQuery =
        MeasurementTask.agent.useMeasurementTasksOfGroups(measuremengGroupIds);

    const usedSamplesIds = useMemo(() => {
        return uniq(pluck("sampleId", tasksQuery.data ?? []));
    }, [tasksQuery.data]);

    const measurementGroups = requestQuery.data ?? [];

    const filteredSamples = useMemo(
        () =>
            samplesQuery.data?.filter((sample) =>
                usedSamplesIds.includes(sample.id),
            ),
        [samplesQuery.data, usedSamplesIds],
    );

    if (
        (!beamtimeQuery.data && beamtimeQuery.isLoading) ||
        (!organizationsQuery.data && organizationsQuery.isLoading) ||
        (!requestQuery.data && requestQuery.isLoading) ||
        (!samplesQuery.data && samplesQuery.isLoading) ||
        (!tasksQuery.data && tasksQuery.isLoading)
    ) {
        return (
            <Center w="100%" h="100%">
                <LoadingSwirl />
            </Center>
        );
    }

    if (
        !beamtimeQuery.data?.data ||
        !requestQuery.data ||
        !filteredSamples ||
        !organizationsQuery.data ||
        !tasksQuery.data
    ) {
        return <Box>Beamtime not found</Box>;
    }

    return (
        <BeamtimeDetails
            beamtime={beamtimeQuery.data.data}
            organizations={organizationsQuery.data}
            noOfSamples={usedSamplesIds.length}
            noOfTasks={tasksQuery.data.length}
            samples={filteredSamples}
            measurementGroups={measurementGroups}
            measurementTasks={tasksQuery.data}
        />
    );
};

BeamtimeDetailsContainer.displayName = "BeamtimeDetailsContainer";

export const BeamtimeDetailsPage = memo(() => {
    return (
        <PageLayout>
            <Box
                overflow="hidden"
                display="flex"
                flexDirection="column"
                w="100%"
                h="100%"
            >
                <BeamtimeDetailsContainer />
            </Box>
        </PageLayout>
    );
});

BeamtimeDetailsPage.displayName = "BeamtimeDetailsPage";
