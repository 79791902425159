import type { FC } from "react";
import { useState } from "react";

import { indexBy } from "ramda";
import type { Promisable } from "type-fest";

import {
    Box,
    Button,
    Center,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Tag,
} from "@chakra-ui/react";
import type { CellContext, ColumnDefTemplate } from "@tanstack/react-table";

import type { OrganizationModel } from "@app/domain/api/organization.ts";
import type { SampleModel } from "@app/domain/api/sample.ts";
import type { SampleTasksModel } from "@app/domain/api/transformer.tsx";
import { SamplesEmptyState } from "@app-components/sample/SamplesEmptyState.tsx";

import type { ToggleItem } from "@mt-components/Button/ToggleButtonBar.tsx";
import { ToggleButtonBar } from "@mt-components/Button/ToggleButtonBar.tsx";
import type { ColumnDef } from "@mt-components/DataTable.tsx";
import { DataTable, makeWidth } from "@mt-components/DataTable.tsx";

import { Icon } from "@mt-design/icons.tsx";

const cell: ColumnDefTemplate<CellContext<SampleTasksModel, unknown>> = (
    row,
) => {
    const value = row.getValue(); // @todo not typesafe
    return (
        <Tag variant="solid" colorScheme={value === true ? "red" : "green"}>
            <>{value === true ? "Yes" : "No"}</>
        </Tag>
    );
};

type TableMeta = {
    // isDisabled?: boolean;
    // onDelete?: (id: string) => void;
    onUpdate: (data: SampleModel) => void;
    organizationsById: Record<string, OrganizationModel | undefined>;
    // onCreate?: (data: Omit<SampleType, "id">) => void;
};

const wideVisibility = {};
const narrowVisibility = {
    qrCode: true,
    casNumber: false,
    hrxrpd: true,
    saxs: true,
    tspdf: true,
    substance: true,
    composition: true,
    name: true,
    otherHazards: false,
    toxic: false,
    flammable: false,
    airSensitive: false,
    sampleHandlingRisk: false,
    sampleHandlingRiskDescription: false,
    formDescription: false,
    oxidizing: false,
    corrosive: false,
    containsHazardousSubstances: false,
    containsHazardousSubstancesDescription: false,
};

function createColumns(tableMeta: TableMeta): ColumnDef<SampleTasksModel>[] {
    return [
        {
            accessorKey: "name",
            header: "Sample-ID",
            thProps: makeWidth("220px"),
        },
        {
            accessorKey: "organizationId",
            header: "Organization",
            cell: (cell) => {
                const meta = tableMeta;
                const organizationId = cell.row.original.organizationId;
                const org = organizationId
                    ? meta.organizationsById[organizationId]
                    : undefined;

                return org?.name ?? "Unknown";
            },
            thProps: makeWidth("200px"),
        },
        {
            accessorKey: "qrCode",
            header: "QR-Code",
            thProps: makeWidth("160px"),
        },
        {
            accessorKey: "hrxrpd",
            header: "HR-XRPD",
            thProps: makeWidth("90px"),
            cell: (cell) => {
                const value = cell.row.original.tasks.hrxrpd;
                return (
                    <Tag variant="solid" colorScheme={value ? "blue" : "gray"}>
                        <>{value ? "Yes" : "No"}</>
                    </Tag>
                );
            },
        },
        {
            accessorKey: "tspdf",
            header: "TS-PDF",
            thProps: makeWidth("90px"),
            cell: (cell) => {
                const value = cell.row.original.tasks.tspdf;
                return (
                    <Tag variant="solid" colorScheme={value ? "blue" : "gray"}>
                        <>{value ? "Yes" : "No"}</>
                    </Tag>
                );
            },
        },
        {
            accessorKey: "saxs",
            header: "SAXS",
            thProps: makeWidth("90px"),
            cell: (cell) => {
                const value = cell.row.original.tasks.saxs;
                return (
                    <Tag variant="solid" colorScheme={value ? "blue" : "gray"}>
                        <>{value ? "Yes" : "No"}</>
                    </Tag>
                );
            },
        },
        {
            accessorKey: "composition",
            header: "Composition",
            thProps: makeWidth("220px"),
        },
        {
            accessorKey: "substance",
            header: "Substance",
            thProps: makeWidth("300px"),
        },
        {
            accessorKey: "casNumber",
            header: "CAS Number",
            thProps: makeWidth("100px"),
        },

        {
            accessorKey: "form",
            header: "Form",
            thProps: makeWidth("140px"),
        },
        {
            accessorKey: "formDescription",
            header: "Form Description",
            thProps: makeWidth("300px"),
        },
        {
            accessorKey: "toxic",
            header: "Toxic?",
            cell,
            thProps: makeWidth("60px"),
        },
        {
            accessorKey: "flammable",
            header: "Flammable?",
            cell,
            thProps: makeWidth("60px"),
        },
        {
            accessorKey: "corrosive",
            header: "Corrosive?",
            cell,
            thProps: makeWidth("60px"),
        },
        {
            accessorKey: "oxidizing",
            header: "Oxidizing?",
            cell,
            thProps: makeWidth("60px"),
        },
        {
            accessorKey: "airSensitive",
            header: "Air-sensitive?",
            cell,
            thProps: makeWidth("60px"),
        },
        {
            accessorKey: "otherHazards",
            header: "Other hazards?",
            cell,
            thProps: makeWidth("60px"),
        },
        {
            accessorKey: "sampleHandlingRisk",
            header: "Sample handling risk?",
            cell,
            thProps: makeWidth("60px"),
        },
        {
            accessorKey: "sampleHandlingRiskDescription",
            header: "Details",
            thProps: makeWidth("240px"),
        },
        {
            accessorKey: "containsHazardousSubstances",
            header: "Contains hazardous substances?",
            thProps: makeWidth("60px"),
            cell,
        },
        {
            accessorKey: "containsHazardousSubstancesDescription",
            header: "Details",
            thProps: makeWidth("240px"),
        },
        {
            accessorKey: "menu",
            header: " ",
            cell: (cell) => {
                const meta = cell.table.options.meta as TableMeta;

                return (
                    <Menu>
                        <MenuButton
                            as={Button}
                            rightIcon={<Icon.ChevronDown />}
                        >
                            Actions
                        </MenuButton>
                        <MenuList>
                            <MenuItem
                                onClick={() => meta.onUpdate(cell.row.original)}
                            >
                                Edit
                            </MenuItem>
                        </MenuList>
                    </Menu>
                );
            },
        },
    ];
}

type Props = {
    isWide?: boolean;
    sampleTasks: SampleTasksModel[];
    organizations: OrganizationModel[];
    onUpdateSample: (data: SampleModel) => Promisable<void>;
};

export const BeamtimeAggregation: FC<Props> = ({
    sampleTasks,
    organizations,
    onUpdateSample,
}) => {
    const [buttonOption, setButtonOption] = useState("narrow");

    const tableMeta: TableMeta = {
        onUpdate: onUpdateSample,
        organizationsById: indexBy((o) => o.id, organizations),
    };

    const toggleButtonBar: ToggleItem<"wide" | "narrow">[] = [
        {
            id: "wide",
            label: "Wide table",
            icon: <Icon.FourColumn />,
            ariaLabel: "expand table",
        },
        {
            id: "narrow",
            label: "Narrow table",
            icon: <Icon.TwoColumn />,
            ariaLabel: "collapse table",
        },
    ];

    return (
        <Box
            display="flex"
            flexDirection="column"
            h="100%"
            w="100%"
            overflowY="auto"
        >
            {Boolean(sampleTasks.length) && (
                <Box py="12px">
                    <ToggleButtonBar
                        onToggle={setButtonOption}
                        option={buttonOption}
                        items={toggleButtonBar}
                    />
                </Box>
            )}
            {sampleTasks.length > 0 ? (
                <Box flex="1" w="100%" overflow="auto">
                    <DataTable<SampleTasksModel>
                        variant="unstyled"
                        state={{
                            columnVisibility:
                                buttonOption === "wide"
                                    ? wideVisibility
                                    : narrowVisibility,
                        }}
                        columns={createColumns(tableMeta)}
                        data={sampleTasks}
                        isSortable
                        meta={tableMeta}
                    />
                </Box>
            ) : (
                <Center w="100%" h="100%">
                    <SamplesEmptyState />
                </Center>
            )}
        </Box>
    );
};

BeamtimeAggregation.displayName = "SamplesTable";
