import { type FC, useMemo } from "react";

import { Center, Checkbox } from "@chakra-ui/react";
import { useNavigate } from "@tanstack/react-router";

import type { MeasurementGroupModel } from "@app/domain";
import type { OrganizationModel } from "@app/domain/api/organization.ts";
import { routes } from "@app/Routes/routes.ts";
import { GroupStatus } from "@app-components/measurementGroup/GroupStatus/GroupStatus.tsx";
import { MeasurementGroupEmptyState } from "@app-components/measurementGroup/MeasurementGroupEmptyState.tsx";

import type { ColumnDef } from "@mt-components/DataTable.tsx";
import { DataTable } from "@mt-components/DataTable.tsx";

import { formatDate } from "@mt-tools/formatter/localization.ts";
import { byId } from "@mt-tools/iterating/filter.ts";

type BeamtimeMeasurementGroupsProps = {
    requests: MeasurementGroupModel[];
    organizations: OrganizationModel[];
};

type ExtendedRequestModal = MeasurementGroupModel & {
    organization: OrganizationModel | null;
};

const columns: ColumnDef<ExtendedRequestModal>[] = [
    {
        accessorKey: "name",
        header: "Name",
    },
    {
        accessorKey: "status",
        header: "Status",
        cell: (cell) => {
            const status = cell.row.original.status;
            return <GroupStatus status={status} />;
        },
    },
    {
        accessorKey: "organization",
        header: "Organization",
        cell: (cell) => {
            return cell.row.original.organization?.name ?? "";
        },
    },
    {
        accessorKey: "updated_at",
        header: "Updated at",
        cell: (cell) => {
            return formatDate(cell.row.original.updatedAt);
        },
    },
    {
        accessorKey: "hasReport",
        header: "Report",
        cell: (cell) => {
            const value = !!cell.getValue();
            return (
                <Checkbox
                    isReadOnly
                    colorScheme="green"
                    defaultChecked={value}
                />
            );
        },
    },
];

export const BeamtimeRequests: FC<BeamtimeMeasurementGroupsProps> = ({
    requests,
    organizations,
}) => {
    const navigate = useNavigate();
    const goToBeamtime = (request: MeasurementGroupModel) =>
        navigate({
            to: routes.agent.measurementGroupDetail.url,
            params: {
                orgId: request.organizationId,
                orderId: request.id,
            },
        });

    const organizationsById = useMemo(
        () => byId(organizations),
        [organizations],
    );

    const merged = requests.map((r) => {
        const organization = organizationsById[r.organizationId];
        return {
            ...r,
            organization: organization ? organization : null,
        };
    });

    return requests.length === 0 ? (
        <Center w="100%" h="100%">
            <MeasurementGroupEmptyState />
        </Center>
    ) : (
        <DataTable<ExtendedRequestModal>
            variant="unstyled"
            initialState={{
                sorting: [
                    {
                        id: "updatedAt",
                        desc: true,
                    },
                ],
            }}
            columns={columns}
            data={merged}
            onRowClick={goToBeamtime}
        />
    );
};
