import type { IconBaseProps } from "react-icons";
import { BsFillFileEarmarkFill, BsThreeDotsVertical } from "react-icons/bs";
import { FaCheck, FaX } from "react-icons/fa6";
import { ImCross } from "react-icons/im";
import { IoMdDownload } from "react-icons/io";
import {
    LuArrowDown,
    LuArrowLeft,
    LuArrowRight,
    LuArrowUp,
    LuCheck,
    LuChevronDown,
    LuChevronLeft,
    LuChevronRight,
    LuChevronUp,
    LuClipboardList,
    LuCog,
    LuDownload,
    LuExternalLink,
    LuEye,
    LuEyeOff,
    LuFileText,
    LuGlobe2,
    LuLineChart,
    LuMail,
    LuMaximize2,
    LuMinimize2,
    LuPackage,
    LuPencil,
    LuQrCode,
    LuRefreshCcw,
    LuScatterChart,
    LuSearch,
    LuTally2,
    LuTally4,
    LuTrash,
    LuUsers,
    LuX,
    LuZoomIn,
    LuZoomOut,
} from "react-icons/lu";
import { MdErrorOutline, MdUpload } from "react-icons/md";
import { TbSquareRoot } from "react-icons/tb";

import {
    CircleCheck,
    File as LuFile,
    FlaskConical,
    Home,
    Info,
    Microscope,
    Plus,
    ReceiptText,
    SquareCheckIcon,
    Telescope,
    User,
    Users,
} from "lucide-react";

import {
    AnalyzeImage,
    MeasurementsImage,
    SamplesImage,
} from "@mt-design/images.tsx";

export const Icon = {
    AnalyzeImage,
    ArrowUp: LuArrowUp,
    ArrowDown: LuArrowDown,
    ArrowLeft: LuArrowLeft,
    ArrowRight: LuArrowRight,
    Beamtime: Telescope,
    Charts: LuLineChart,
    Check: LuCheck,
    SquareCheck: SquareCheckIcon,
    Check2: LuCheck,
    ChevronDown: LuChevronDown,
    ChevronLeft: LuChevronLeft,
    ChevronRight: LuChevronRight,
    ChevronUp: LuChevronUp,
    ClipboardList: LuClipboardList,
    Cog: LuCog,
    CloudDone: CircleCheck,
    Close: LuX,
    Cross: ImCross,
    Document: LuFileText,
    Delete: LuTrash,
    Download: IoMdDownload,
    ExternalLink: LuExternalLink,
    Edit: LuPencil,
    Error: MdErrorOutline,
    Form: ReceiptText,
    File: LuFile,
    File2: BsFillFileEarmarkFill,
    FourColumn: LuTally4,
    Globe: LuGlobe2,
    Hidden: LuEyeOff,
    Home: Home,
    Info: Info,
    LineChart: LuLineChart,
    MeasurementsImage,
    Maximize: LuMaximize2,
    Minimize: LuMinimize2,
    Mail: LuMail,
    Menu: BsThreeDotsVertical,
    Organization: Users,
    Package: LuPackage,
    Plus: Plus,
    Profile: User,
    QRCode: LuQrCode,
    Request: Microscope,
    Sample: FlaskConical,
    SamplesImage,
    ScatterChart: LuScatterChart,
    Search: LuSearch,
    Shown: LuEye,
    TwoColumn: LuTally2,
    Upload: MdUpload,
    Users: LuUsers,
    Success: (props: IconBaseProps) => <FaCheck {...props} color="green" />,
    Failure: (props: IconBaseProps) => <FaX {...props} color="red" />,
    graph: {
        Square: TbSquareRoot,
        Download: LuDownload,
        ZoomIn: LuZoomIn,
        ZoomOut: LuZoomOut,
        Reload: LuRefreshCcw,
    },
};
